import ajax from "@/utils/ajax.js";

/**
 * 钱包礼品卡发票：发票列表
 * @param {*} params
 * @returns
 */
export function getGiftInvoiceList(params) {
  return ajax.post("/RechargeCardInvoiceApply/getList", params);
}

/**
 * 钱包礼品卡发票：发票详情
 * @param {*} params
 * @returns
 */
export function getGiftInvoiceDetail(params) {
  return ajax.post("/RechargeCardInvoiceApply/detail", params);
}


/**
 * 钱包礼品卡发票：审核
 * @param {*} params
 * @returns
 */
export function auditGiftInvoice(params) {
  return ajax.post("/RechargeCardInvoiceApply/audit", params);
}

/**
 * 钱包礼品卡发票：开票
 * @param {*} params
 * @returns
 */
export function openGiftInvoice(params) {
  return ajax.post("/RechargeCardInvoiceApply/open", params);
}

